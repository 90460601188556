import React from 'react'
import Page from '../components/Page'

const StrategyServicesPage = () => (
  <Page title="strategy services for china patent litigation and IPS proceedings" image="/img/192.jpg">
      <p className="mt-8 w-2/3 text-center">
        We assist our customers in<br/>
      </p>
      <ul>
        <li>
          planning and developing IP China strategy for patent nullity and infringement proceedings in China
        </li>
        <li>
          searching, making, and building network and connections to the most politically powerful and best connected lawyers in relevant Chinese cities, counties, and provinces
        </li>
        <li>
          initiating and leading negotiations with Chinese lawyers, Chinese cooperation partners, Chinese competitors
        </li>

      </ul>
  </Page>
)

export default StrategyServicesPage